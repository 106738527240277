import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ClockIcon } from '../../assets/clock.svg'
import { ReactComponent as TempIcon } from '../../assets/temp.svg'
import { ReactComponent as HumidityIcon } from '../../assets/humidity.svg'
import { ReactComponent as RainIcon } from '../../assets/rain.svg'
import { ReactComponent as PrecipitationIcon } from '../../assets/precipitation.svg'
import { ReactComponent as WindIcon } from '../../assets/wind.svg'
import {
  assessAlgorithmInputs,
} from '../../utils/assessment'

const Root = styled.div({
  minWidth: '120px',
})

const FlexRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const IconWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  width: '24px',
  height: '24px',
})

function AssessmentTooltipContents({ assessment, product }) {
  const {
    score,
    assessmentValidityPeriodStart,
    assessmentValidityPeriodEnd,
    algorithmInputs,
  } = assessment

  const startTime = moment(assessmentValidityPeriodStart).format('ddd H:mm')
  const endTime = moment(assessmentValidityPeriodEnd).format('H:mm')

  const algorithm = product.applicableAlgorithm.split(':')[0].toUpperCase()

  const {
    temperatureFeelLike: tempScore,
    relativeHumidity: humidityScore,
    quantitativeRainfall: rainfallScore,
    precipChance: precipScore,
    windSpeed: windScore,
  } = assessAlgorithmInputs(algorithm, algorithmInputs)

  const assessmentSummaries = [
    {
      Icon: TempIcon,
      score: tempScore,
      label: 'Cold',
    },
    {
      Icon: RainIcon,
      score: rainfallScore,
      label: 'Rain',
    },
    {
      Icon: HumidityIcon,
      score: humidityScore,
      label: 'Humid',
    },
    {
      Icon: PrecipitationIcon,
      score: precipScore,
      label: 'Precip'
    },
    {
      Icon: WindIcon,
      score: windScore,
      label: 'Wind',
    }
  ]

  return (
    <Root>
      <FlexRow style={{ marginBottom: '4px' }}>
        <IconWrapper>
          <ClockIcon width={16} height={16} fill="white" />
        </IconWrapper>

        <span style={{ marginLeft: '4px' }}>
          {startTime} - {endTime}
        </span>
      </FlexRow>

      {score === 0
        ? null
        : assessmentSummaries.map(({ Icon, score: individualScore, label }) => {
            if (!individualScore || individualScore === 0) {
              return null
            }

            return (
              <FlexRow>
                <IconWrapper>
                  <Icon width={24} height={24} fill="#FF7878" />
                </IconWrapper>

                <span style={{ marginLeft: '4px', color: '#FF7878' }}>{label}</span>
              </FlexRow>
            )
          })}
    </Root>
  )
}

export { AssessmentTooltipContents }
