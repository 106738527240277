import { isNaN, mean, sum } from '@soltalabs/ramda-extra'
import CONSTANTS from '@roadscience/constants'

import { COLORS } from '../constants/colors'

const { ALGORITHM_INPUTS, THRESHOLDS } = CONSTANTS

const INPUT_AGGREGATOR = {
  [ALGORITHM_INPUTS.TEMPERATURE_FEEL_LIKE]: mean,
  [ALGORITHM_INPUTS.RELATIVE_HUMIDITY]: mean,
  [ALGORITHM_INPUTS.QUANTITATIVE_RAINFALL]: sum,
  [ALGORITHM_INPUTS.PRECIP_CHANCE]: mean,
  [ALGORITHM_INPUTS.WIND_SPEED]: mean,
}

function getColorFromAssessmentScore(value) {
  if (value <= 0) {
    return COLORS.ASSESSMENT.GREEN
  }
  if (value < 1) {
    return COLORS.ASSESSMENT.YELLOW
  }

  return COLORS.ASSESSMENT.RED
}

function assessAlgorithmInputs(algorithm, algorithmInputs) {
  const scores = {}

  for (const [input, value] of Object.entries(algorithmInputs)) {
    const aggregator = INPUT_AGGREGATOR[input]

    if (!aggregator || isNaN(value)) {
      scores[input] = 0
      continue
    }

    const aggregatedValue = aggregator(value)
    scores[input] = calculateScore(algorithm, input, aggregatedValue)
  }

  return scores
}

function calculateScore(algorithm, input, aggregatedValue) {
  const algorithmThresholds = THRESHOLDS[algorithm] ?? THRESHOLDS.DEFAULT
  const inputThreshold = algorithmThresholds[input]

  if (!inputThreshold) {
    return 0
  }

  let score = 0

  if (inputThreshold.MIN) {
    score = aggregatedValue < inputThreshold.MIN ? 0 : 1
  } else if (inputThreshold.MAX) {
    score = aggregatedValue > inputThreshold.MAX ? 0 : 1
  }

  return score
}

export {
  assessAlgorithmInputs,
  getColorFromAssessmentScore,
}
